.sideBar {
  flex: 3;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  position: sticky;
  top: 0;
}
.wrapper {
  padding: 20px;
}
.list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.listItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.icon {
  margin-right: 15px;
}
.button {
  width: 150px;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}
.sideBarHr {
  margin: 20px 0;
}

.friendList {
  padding: 0;
  margin: 0;
  list-style: none;
}
.friend {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 15px;
}
.friendImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
