.container {
  height: 50px;
  width: 100%;
  background-color: #1877f2;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
}
.left {
  flex: 3;
}
.logo {
  font-size: 24px;
  margin-left: 20px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
.searchBar {
  width: 100%;
  height: 30px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
}
.searchIcon {
  font-size: 20px;
  margin-left: 10px;
}
.searchInput {
  border: none;
  width: 70%;
}
.searchInput:focus {
  outline: none;
}
.center {
  flex: 5;
}
.right {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
}
.link {
  margin-right: 10px;
  font-size: 14px;
  cursor: pointer;
}
.icons {
  display: flex;
}
.icon {
  margin-right: 15px;
  cursor: pointer;
  position: relative;
}
.awesomeIcon {
  font-size: 20px;
}
.iconBadge {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: -40%;
  right: -40%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.topbarImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}
