.rightBar {
  flex: 3.5;
}

.wrapper {
  padding: 20px 20px 0 0;
}
.birthdayContainer {
  display: flex;
  align-items: center;
}
.birthdayImg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.birthdayText {
  font-weight: 300;
  font-size: 15px;
}

.rightBarAd {
  width: 100%;
  border-radius: 10px;
  margin: 30px 0;
}

.rightBarTitle {
  margin-bottom: 20px;
}

.friendsList {
  padding: 0;
  margin: 0;
  list-style: none;
}
.friend {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.profileImgContainer {
  margin-right: 10px;
  position: relative;
}

.profileImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.online {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: limegreen;
  position: absolute;
  top: -2px;
  right: 0;
  border: 2px solid white;
}

.userName {
  font-weight: 500;
}
